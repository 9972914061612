<div class="table-responsive">
    <table class="table table-hover mt-3">
        <thead>
            <th>{{ 'columns.family' | translate | uppercase}}</th>
            <th>{{ 'columns.product' | translate | uppercase}}</th>
            <th>{{ 'columns.customer' | translate | uppercase}}</th>
            <th class="text-center">{{ 'columns.ticket' | translate | uppercase}}</th>
            <th>{{ 'columns.reseller' | translate | uppercase}}</th>
            <th class="text-center">{{ 'columns.id_venditore' | translate | uppercase}}</th>
            <th>{{ 'columns.sell_date' | translate | uppercase}}</th>
            <th>{{ 'columns.validity_period' | translate | uppercase}}</th>
            <th>{{ 'columns.price_vat' | translate | uppercase}}</th>
            <th class="text-center">{{ 'columns.status' | translate | uppercase}}</th>
            <!--<th class="text-center">{{ 'columns.pagato_con' | translate | uppercase}}</th>-->
        </thead>
        <tbody>
            <tr *ngFor="let ticket of elementList ; let i = index;">
                <td>{{ ticket.productFamilyName }}</td>
                <td><p class="mb-0">{{ ticket.name }} {{ ticket?.additionalInfoExternal?.customFieldsExternal?.uncoverd | covered }}</p></td>
                <td>{{ ticket.customer?.name }} {{ ticket.customer?.surname }}</td>
                <td class="text-center">
                    {{ ticket.shortCode }}
                    <div *ngIf="ticket.additionalInfoOrderDetail.shortCodeTicketRef" style="line-height: 1;">
                        <span class="table-column-info">
                            <b>{{'columns.ticket_ref' | translate}}</b>:<br/>
                            <span class="t-code">{{ ticket.additionalInfoOrderDetail.shortCodeTicketRef }}</span>
                        </span>
                    </div>
                    <div *ngIf="ticket.additionalInfo && ticket.additionalInfo.attribute && ticket.additionalInfo.attribute.length && ticket.additionalInfo.attribute[0].isFromCarnet">
                        <span class="badge badge-pill badge-secondary">
                            {{'base.carnet' | translate}}
                        </span>
                    </div>
                </td>
                <td>
                    <ng-container *ngIf="ticket.reseller?.businessName"> {{ticket.reseller?.businessName}}<br /></ng-container>
                    ({{ticket.additionalInfoPriceList.name}})
                </td>
                <td class="text-center">{{ticket.reseller?.id}}</td>
                <td>{{ ticket.purchaseDate | date:'dd/MM/yyyy' }}</td>
                <td>{{ ticket.validityFrom | italianTime }} - {{ ticket.validityTo | italianTime }}</td>
                <td>
                    <ng-container *ngIf="ticket.additionalInfo && ticket.additionalInfo.attribute && ticket.additionalInfo.attribute.length && ticket.additionalInfo.attribute[0].isFromCarnet">{{ ticket.additionalInfoPrice.unitPrice / ticket.additionalInfo.attribute[0].ticketNumberInCarnet | s1Currency: true }} (unitario)<br></ng-container>
                    <ng-container *ngIf="ticket.additionalInfoPrice.unitPrice != (ticket.additionalInfoPrice.total / ticket.additionalInfoPrice.quantity)" ><s>{{ ticket.additionalInfoPrice.unitPrice | s1Currency: true }}</s><br/></ng-container> {{ (ticket.additionalInfoPrice.total / ticket.additionalInfoPrice.quantity) | s1Currency: true }} <ng-container *ngIf="ticket.additionalInfo && ticket.additionalInfo.attribute && ticket.additionalInfo.attribute.length && ticket.additionalInfo.attribute[0].isFromCarnet">(carnet)</ng-container><br>
                    {{ ticket.additionalInfoPrice.vat }}%
                </td>
                <td class="text-center d-flex flex-column align-items-center justify-content-center">
                    <div class="sac-status">
                        <span class="bg-warning" *ngIf="ticket.ticketState == State.INACTIVE">
                            {{'state.inactive' | translate}}
                          </span>
                          <span class="bg-success" *ngIf="ticket.ticketState == State.ACTIVE">
                            {{'state.active' | translate}}
                        </span>
                    </div>
                </td>
                
                <!--<td *ngIf="!ticket.sale" class="text-center">
                    <span *ngIf="!ticket.sale?.freeGift">{{ticket.sale?.paymentType}}</span>
                    <i *ngIf="ticket.sale?.freeGift" class="fas fa-gift fa-2x text-info"></i>
                </td>-->
            </tr>
        </tbody>
        <tfoot *ngIf="elementList.length==0">
            <tr>
                <td colspan="8"
                    class="text-center">
                    {{ 'record_not_found.product' | translate }}
                </td>
            </tr>
        </tfoot>
    </table>
</div>