import { Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { LoggingService } from './log.service';
import { Observable } from 'rxjs';
import { SettingsService } from '../settings/settings.service';
import { RestfulService } from './restful.service';
import { APPCONFIG } from '../../app.config';
import { getEndPointWithParams, dateFormatting } from '../../../assets/js/util.js';
import { RequestBody } from '../interfaces/admin.interface';
import * as ENDPOINT from '../../shared/constant/endPoint.js';
import { ReqBody_action } from '../interfaces/order.interface';
import { saveAs } from 'file-saver/src/FileSaver.js';


@Injectable({
    providedIn: 'root'
})
export class OrderService {

    constructor(private logger: LoggingService, private settings: SettingsService, private restful: RestfulService) { }
    

    getOrderList(reqBody: RequestBody): Observable<any> {        
        let endPoint = getEndPointWithParams(ENDPOINT.listOrder_all);  
        return this.post(endPoint, reqBody);
    }

    orderAction(reqBody: ReqBody_action): Observable<any> {         
        return this.post(ENDPOINT.listOrder_action, reqBody);
    }

    getOrderDetailList(idOrder: string): Observable<any> {        
        let endPoint = getEndPointWithParams(ENDPOINT.listOrder_orderDetail, idOrder);  
        return this.get(endPoint);
    }

    getOrderTicketList(idOrder: number): Observable<any> {        
        let endPoint = getEndPointWithParams(ENDPOINT.ticket_by_order, idOrder);  
        return this.get(endPoint);
    }

    downloadOrderReport(reqBody: RequestBody):Observable<any> {        
        let endPoint = ENDPOINT.listOrder_allExport;
        return this.post_downloadFile(endPoint, reqBody);
    }

    cancelOrder(idOrder: number): Observable<Boolean> {
        let endPoint = getEndPointWithParams(ENDPOINT.order_refund, idOrder);
        return this.put(endPoint, null);
    }


    post(endPoint: string, reqBody: any): Observable<any> {
        return Observable.create((observer) => {
            this.restful.post(endPoint, reqBody).subscribe((response: HttpResponse<any>) => {
                this.responseHandler(response, observer);
            });
        });
    }

    post_downloadFile(endPoint: string, reqBody: any): Observable<any> {
        return Observable.create((observer) => {
            this.restful.post_downloadFile(endPoint, reqBody).subscribe((response: HttpResponse<any>) => {
                this.responseHandler_downloadFile(response, observer);
            },error =>{
                observer.error("download file error");
            });
        });
    }


    put(endPoint: string, reqBody: any): Observable<any> {
        return Observable.create((observer) => {
            this.restful.put(endPoint, reqBody).subscribe((response: HttpResponse<any>) => {
                this.responseHandler(response, observer);
            });
        });
    }

    delete(endPoint: string): Observable<any> {
        return Observable.create((observer) => {
            this.restful.delete(endPoint).subscribe((response: HttpResponse<any>) => {
                this.responseHandler(response, observer);
            });
        });
    }

    get(endPoint: string): Observable<any> {
        return Observable.create((observer) => {
            this.restful.get(endPoint).subscribe((response: HttpResponse<any>) => {
                this.responseHandler(response, observer);
            });
        });
    }

    responseHandler(response: HttpResponse<any>, observer: any): void {
        const outcome = response['outcome'];
        if (outcome.success === true) {
            this.logger.log("Service:", "SUCCESS", 200);
            observer.next(response);
        } else {
            this.logger.log("Service:", "FAILURE", 200);
            outcome.message = this.settings.manageErrorMsg(outcome);
            observer.error(outcome);
        }
    }

    responseHandler_downloadFile(response: HttpResponse<any>, observer: any): void {
        saveAs(response, "orders_"+dateFormatting(new Date())+".xls");
        observer.next(response);
    }
}



