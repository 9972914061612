import { Component, Input } from '@angular/core';
import { State } from '@app/core/interfaces/admin.interface';
import { Ticket } from '@app/core/interfaces/ticket.interface';
import { OrderService } from '@app/core/services/order.service';

@Component({
  selector: 'sacbo-tickets-table',
  templateUrl: './tickets-table.component.html',
  styleUrl: './tickets-table.component.scss'
})
export class TicketsTableComponent {

  @Input() set idOrder(newValue: number) {

    this.orderService.getOrderTicketList(newValue).subscribe(ticketsResp => {
      this.elementList = ticketsResp?.data ?? [];
    });

  }

  elementList: Ticket[] = [];

  State = State;

  constructor(private orderService: OrderService) {

  }

}
